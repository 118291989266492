let dataTable;

const App = {

    init() {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            }
        });

        $(document).on('submit', '.appForm', App.submitAppForm);
        $(document).on('click', '.deleteRequest', App.deleteRequest);

    },
    baseUrl(path = '') {
        return $('meta[name="base-url"]').attr('content') + path;
    },

    routes: {
        trackOrder: $('meta[name="base-url"]').attr('content') + '/customer/track-order',
    },

    loader() {
        return '<div class="text-center"> <i class="fas fa-spin fa-3x fa-circle-notch"></i> <br> Loading..</div>';
    },

    //submit global form
    submitAppForm() {
        event.preventDefault();
        let form = new FormData($('.appForm')[0]);
        let url = $('.appForm').attr('action');
        let submitBtn = $('.appForm--submit');
        let responseBlock = $('.appForm--response');
        responseBlock.html(`${App.loader()}`);
        submitBtn.prop('disabled', true);
        axios.post(url, form).then((response) => {
            responseBlock.html(`<div class="alert alert-success"> ${response.data.message}</div>`);
            if (response.data.url != undefined)
                window.location.href = response.data.url;
            submitBtn.prop('disabled', false);
        }).catch((error, other) => {
            console.log(error.response.data.errors)
            responseBlock.html('');
            submitBtn.prop('disabled', false);
            error.response.data.errors.map((err) => {
                responseBlock.append(`<div class="alert alert-danger my-1"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> ${err}</div>`)
            })
        });
    },

    //delete request
    deleteRequest() {
        let route = event.target.getAttribute('data-url');
        let message = event.target.getAttribute('data-message')
        message = message == undefined ? 'Yes, delete it!' : message;

        if (confirm(message)) {
            axios.delete(route).then((response) => {
                Swal.fire("Deleted!", response.data.message, "success");
                dataTable.draw(false);
            }).catch((error) => {
                console.error(error);
                Swal.fire("Error!", 'Contact support', "error");
            });
        }
    },

    //datatable cols
    dataTable: (cols) => {
        var url = $("#dataTable").attr('data-url');
        dataTable = $('#dataTable').DataTable({
            dom: "<'row'<'col-md-12 'Bfl>r>" +
                "<'row'<'col-md-12't>>" +
                "<'row'<'col-md-12'ip>>",
            buttons: [],
            "pageLength": 25,
            processing: true,
            serverSide: true,
            responsive: true,
            ajax: {
                url: url,
            },
            columns: cols
        });

        return dataTable;

    },

};
export default App;
