require('./bootstrap');
import Swal from 'sweetalert2'
import App from './modules/common.js';


window.App = App;
window.Swal = Swal;
// window.Cart = Cart;

App.init();
// Cart.init();
